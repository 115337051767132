import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent, CardHeader, TextField, TextareaAutosize } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { privateRoute } from 'routes';
import { systemService } from 'services';

const Home = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: checkCreditCard, isLoading } = useMutation(systemService.checkCreditCard, {
    onSuccess() {
      navigate(privateRoute.users.path);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      checkCreditCard({
        ...(values as CheckCreditCardType),
      });
    })();
  };

  return (
    <div>
      <Card>
        <CardHeader title='Enter Card Information' className='pb-0' />
        <CardContent>
          <Controller
            name='data'
            defaultValue=''
            control={control}
            rules={{ required: 'Dữ liệu không được để trống' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{
                  inputComponent: TextareaAutosize,
                  inputProps: {
                    minRows: 3,
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </CardContent>
      </Card>
      <div className='flex items-center justify-start mt-4'>
        <LoadingButton
          variant='contained'
          color='secondary'
          startIcon={<Save />}
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          Check
        </LoadingButton>
      </div>
    </div>
  );
};

export default Home;
