import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const ErrorPage: FC<{ [x: string]: any }> = ({ onRedirect }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className='min-h-screen bg-black/20 flex justify-center'>
        <div className='w-80 my-auto flex flex-col items-center'>
          <img src={require('assets/images/404.png')} />
          <div className='text-center mt-6'>
            <div className='text-xl font-medium'>Oops!</div>
            <span className='text-sm'>We’ve run into an issue. Please try again later.</span>
          </div>
          <Button
            variant='contained'
            onClick={() => {
              navigate('/');
              onRedirect && onRedirect();
            }}
            className='mt-6'
          >
            Go to homepage
          </Button>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
