import { client } from './axios';

const getSystem = (params?: any): Promise<SystemPaginateType> => client.get(`/api/systems`, { params });
const updateSystem = ({ id, ...body }: UpdateSystemType): Promise<SystemRecordType> =>
  client.patch(`/api/systems/${id}`, body);
const checkCreditCard = (body: CheckCreditCardType): Promise<CreditCardResponseType> =>
  client.post(`/api/systems/check-cc`, body);

const systemService = {
  getSystem,
  updateSystem,
  checkCreditCard,
};
export default systemService;
