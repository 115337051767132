import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export const systemSlice = createSlice({
  name: 'system',
  initialState: {} as SystemRecordType,
  reducers: {
    saveSystem: (state, { payload }) => {
      state = { ...payload, ...state };
      return state;
    },
  },
});

export const { saveSystem } = systemSlice.actions;

export const systemSelector = ({ system }: RootState) => system;

export default systemSlice.reducer;
