import { Refresh } from '@mui/icons-material';
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components';
import { useSearch } from 'hooks';
import { systemService } from 'services';

const UserList = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching, refetch } = useQuery(
    ['userService.fetchUsers', dataSearch],
    () => systemService.getSystem(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], total, currentPage, pages } = data ?? {};

  return (
    <>
      <div className='flex items-center justify-end gap-3'>
        <Button variant='outlined' color='primary' startIcon={<Refresh />} onClick={() => refetch()}>
          Làm mới
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Spinner loading={isFetching}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item: any) => (
                <TableRow key={item.id}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.data}</TableCell>
                  <TableCell>{item.message}</TableCell>
                  <TableCell>
                    {item.status === 4
                      ? 'DONE'
                      : item.status === 3
                      ? 'FAILED'
                      : item.status === 2
                      ? 'PROCESSING'
                      : 'PENDING'}
                  </TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={!isFetching && items.length === 0} />
            </TableBody>
            <caption>{total ?? 0} Người dùng</caption>
          </Table>
        </Spinner>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={currentPage ?? 1}
          count={pages}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </>
  );
};

export default UserList;
